
import logo from '../../assets/imgs/logo.png'
import { AiFillYoutube, AiFillInstagram } from 'react-icons/ai'
import { Link } from 'react-router-dom'
import React from 'react';


function HomePage() {



    const scrollToSection = (sectionId) => {
        if (
            sectionId === 'quemsomos' ||
            sectionId === 'consultingservices' ||
            sectionId === 'taxservice' ||
            sectionId === 'bookkeping' ||
            sectionId === 'recursos' ||
            sectionId === 'depoimentos' ||
            sectionId === 'contato'
        ) {
            // Verifique se estamos na página principal
            if (window.location.pathname !== '/') {
                // Redirecione para a página principal
                window.location.href = '/';
            }

            const section = document.getElementById(sectionId);
            if (section) {
                // Defina a margem superior desejada (por exemplo, 20 pixels)
                const margin = 135;
                // Calcule o ponto de destino com margem
                const offsetTop = section.offsetTop - margin;

                window.scrollTo({
                    top: offsetTop,
                    behavior: 'smooth',
                });
            }
        }
    };




    return (


        <footer className='content-footer'>
            <div className="content-info-org">

                <div className='org-info-footer'>

                    <div className='org-img-icons'>
                        <div>
                            <Link to="/">
                                <img src={logo} alt="" />
                            </Link>

                        </div>
                        <div className='icones'>
                            <a href=" https://www.youtube.com/@canadasimplificado" target='_blank'>
                                <AiFillYoutube className='icone-tam' />

                            </a>
                            <a href='https://www.instagram.com/canadasimplificado/' target='_blank'>
                                <AiFillInstagram className='icone-tam' />
                            </a>

                        </div>
                    </div>
                    <div className='menu-footer'>
                        <div>
                            <span href="" onClick={() => scrollToSection('consultingservices')}>
                                <p>Consulting Services</p>
                            </span>
                            <span href="" onClick={() => scrollToSection('taxservice')}>
                                <p>Tax Services</p>
                            </span>
                            <span href="" onClick={() => scrollToSection('bookkeping')}>
                                <p>Bookkeeping</p>
                            </span>
                        </div>
                        <div>
                            <span href="" onClick={() => scrollToSection('recursos')}>
                                <p>Recursos</p>
                            </span>
                            <span href="" onClick={() => scrollToSection('depoimentos')}>
                                <p>Depoimentos</p>
                            </span>
                            <span href="" onClick={() => scrollToSection('contato')}>
                                <p>Contato</p>
                            </span>
                        </div>
                    </div>
                </div>

                <hr className='footer-hr' />

                <p>©2021 by Canadá Simplificado</p>

            </div>
        </footer>

    );
}

export default HomePage;
